import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import __ from '../../localisation';

const StyledNextButton = styled(motion.button)`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    pointer-events: ${({ visible }) => visible ? 'unset' : 'none'};

    position: absolute;
    z-index: 10;
    top:  30px;
    left: auto;
    right: 15px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.primary};

    transition: box-shadow 0.2s ease-out;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px white;
    }

    /* The icon */
    svg {
      stroke: ${({ theme }) => theme.color.white};
      transform: rotate(-90deg);
      margin: auto;
      width: 30px;
    }

    /* Special solution for mobile only, NEXT-button */
    /* @media  screen and (max-width: 1000px) { */
      background-color: ${({ theme }) => theme.color.white};
      position: absolute;
      bottom: 0;
      top: auto;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0;

      & > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > p {
          display: flex;
          font-weight: bold;
          color: ${({ theme }) => theme.color.primary};
          margin-right: ${({ theme }) => theme.spacing.small};
        }

        & > svg {
          stroke: ${({ theme }) => theme.color.primary};
          transform: rotate(-90deg);
          margin: 0;
          width: 30px;
        }
      /* } */
    }


    /* @media  screen and (min-width: 1000px) {
      top: calc(50% - 24px);


      p {
        display: none;
      }

    } */
`;


const NextButton = ({ onClick, left, visible, lang }) => {
  const buttonAnim = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    hover: { opacity: 1 },
    active: { opacity: 1, scale: 0.95 },
  }

  const iconAnim = {
    hidden: { x: left ? 0 : -10, scale: left ? 1 : 1.5, rotate: left ? 0 : -10 },
    visible: { x: 0, scale: 1, rotate: 0 },
    hover: { x: left ? -5 : 5, scale: 1, rotate: 0 },
    active: { x: left ? -5 : 5, scale: 1, rotate: 0 },
  }

  const nextOuterAnim = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: "easeOut" },
    hover: { opacity: 1 },
    active: { opacity: 0.9 },
  }

  const nextInnerAnim = {
    hidden: { x: 0, },
    visible: { x: 0, },
    hover: { x: 5 },
    active: { x: 0 },
  }

  return (
    <StyledNextButton
      variants={left ? buttonAnim : nextOuterAnim}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
      whileHover="hover"
      whileTap="active"
      onClick={onClick}
      left={left}
      visible={visible}
    >
      <motion.div
        variants={left ? iconAnim : nextInnerAnim}
      >
        {!left &&
          <p>{__('next', lang)}</p>
        }
        <svg width="30" height="30" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 1.5L7 6.5L1.5 1.5" strokeWidth="1" strokeMiterlimit="10" />
        </svg>

      </motion.div>

    </StyledNextButton>
  );
};

NextButton.defaultProps = {
  visible: true
}

NextButton.propTypes = {
  onClick: PropTypes.func,
  left: PropTypes.bool,
  hide: PropTypes.bool,
  onlyMobile: PropTypes.bool,
  onlyDesktop: PropTypes.bool,
  absolute: PropTypes.bool,
};

export default NextButton;
import { createGlobalStyle } from 'styled-components';
import apienv from '../apienv';
// import NeoSansRegular from '../assets/fonts/NeoSansW1G-Regular.otf';
// import NeoSansBold from '../assets/fonts/NeoSansW1G-Bold.otf';

// NOTE: Fonterna funkar inte lokalt just nu när man testart widget. Men antagligen funkar dem när vi lägger widgeten på en https address

export const DefaultGlobals = createGlobalStyle`

  @font-face {
    font-family: 'Neo Sans';
    src: url(${apienv.imgStorage}/fonts/NeoSansW1G-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neo Sans';
    src: url(${apienv.imgStorage}/fonts/NeoSansW1G-Bold.woff2) format('woff2');
    font-weight: bold;
    font-style: bold;
  }

  #bauerBooking {

    font-size: 16px;
    line-height: 1.4;
    position: relative;
    width: 100%;
    min-height: 400px;
    height: 100%;
    /* overflow: auto; */
    background: ${({ theme }) => theme.color.primary};

    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: ${({ theme }) => theme.font.family.body};
      box-sizing: border-box;
    }

    /* Prevent iOS from zooming in */
    input, select:focus, textarea {
      font-size: 16px;
      height: auto;
    }

    /* *:not(#availability) {
      margin: 0;
      padding: 0;
      background: none;
      &:after, &:before {
        content: none;
      }
    } */

    /* Base reset */
    h1, h2, h3, h4, h5, h6, img, p, a, button
    {
      margin: 0;
      padding: 0;
      max-width: unset;

      /* background: transparent;
      border: none;
      color: inherit;
      font: inherit;
      height: auto;
      letter-spacing: normal;
      line-height: normal;
      text-transform: none;
      visibility: visible;
      width: auto;
      word-spacing: normal;
      z-index: auto; */
    }
    h1, h2, h3, h4, h5, h6, p {
      display: block;
    }

    img {
      max-width: 100%;
    }

    p {
        margin: 1em 0;
    }

    /* Normalize */
    h1, h2, h3, h4, h5, h6 {
      color: inherit;
      font-size: 14px;
      font-weight: bold;
    }
    h2 {
      font-size: ${({ theme }) => theme.font.size.bodyBig};
      text-transform: uppercase;
    }

    a {
      color: ${({ theme }) => theme.color.primary};;
    }

    .BauerBookingWidget {
      font-size: ${({ theme }) => theme.font.size.body};
      font-size: ${({ theme }) => theme.font.size.base};
      margin: 0;
      min-height: 410px;
      height: 100%;
      font-family: ${({ theme }) => theme.font.family.body};
      font-family: ${({ theme }) => theme.font.height.body};
      letter-spacing: ${({ theme }) => theme.font.spacing.spacing};
      position: relative;
      background-image: url(${apienv.imgStorage}/images/Bauer2.svg);
      /* background-position: center calc(110px + 2vw); */
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;

      @media  screen and (max-width: 1000px){
        background-image: url(${apienv.imgStorage}/images/Bauer-rotated.svg);
        background-size: auto 100%;
        background-position: top center;
      }
      /* display: flex;
      align-items: center;
      justify-content: center; */
    }
    /* .onsched-calendar-row.onsched-weekdays {
      height:0;
    }
    .onsched-calendar-col.dow {
      display:none;
    } */
  }


`;

export default DefaultGlobals;

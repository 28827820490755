import React from 'react';
import styled from 'styled-components';
// import { ReactComponent as LoadingAnim } from '../../assets/loading-anim.svg';

const StyledLoadingScreen = styled.div`
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 50px);
    display: -webkit-flex;
    display: flex;
    color: white;
    opacity: ${({ loading }) => loading ? 1 : 0};
    transition: opacity 0.3s linear;
    pointer-events: none;

    .spinner__wrap {
      margin: auto;
    }
    .spinner {
      stroke-dashoffset: 0;
      animation: loading-spin 0.3s linear infinite;
    }
    @keyframes loading-spin {
      0% { stroke-dashoffset: 0 }
      100% { stroke-dashoffset: -13.33 }
    }
    .animation-glare1 {
      animation: loading-move1 2s cubic-bezier(0.5, 0, 0.5, 1) alternate infinite;
    }
    .animation-glare2 {
      animation: loading-move2 2s cubic-bezier(0.5, 0, 0.5, 1) alternate infinite;
    }
    @keyframes loading-move1 {
      0% { transform: translate(0, -5px) }
      100% { transform: translate(0, 15px) }
    }
    @keyframes loading-move2 {
      0% { transform: translate(0,-5px) }
      100% { transform: translate(0, 12px) }
    }

    .loading-puck {
      clip-path: url(#loading-clip-path)
    }
`;

const LoadingScreen = ({ loading }) => {
  return (
    <StyledLoadingScreen loading={loading}>
      {/* <LoadingAnim style={{ width: '200px' }} /> */}
      <svg height="100" width="100" className="spinner__wrap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <defs>
          <clipPath id="loading-clip-path">
            <path id="mask" fill="none" d="M50,65c-12,0-23-4.27-28.84-10.91a15,15,0,0,1-3.3-5.68A10.47,10.47,0,0,1,17,43.9C17,32.19,31.83,22.74,50,22.74S83.05,32.19,83.05,43.9a12.83,12.83,0,0,1-.79,4.51A15.89,15.89,0,0,1,79,54.09c-5.92,6.7-17,11-29,11Z" />
          </clipPath>
        </defs>
        <circle
          className="spinner"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeDasharray="2, 4.89"
          cx="50" cy="50" r="48.33"
        />
        <rect fill="none" x="15.85" y="21.64" width="68.3" height="56.71" />
        <g className="loading-puck">
          <path className="animation-glare2" fill="#fff" d="M158.61,99.88l.91-1.52-141-83.42-.85,1.58,141,83.36Z" />
          <path className="animation-glare1" fill="#fff" d="M78.84,66.16H92.69L1.64,12.5l-9,2.8Z" />
        </g>
        <path fill="#fff" d="M84.15,43.9C84.1,31.67,68.81,21.64,50,21.64s-34.15,10-34.15,22.26V59.33A11.23,11.23,0,0,0,17,64.15c.24.3.37.67.61.85l.3.43.55.73c5.92,7.2,17.75,12.2,31.53,12.2,12.44,0,23.48-4.09,29.64-10.13.73-.79,1.4-1.46,2-2.19l.24-.37.92-1.28.3-.61a8.62,8.62,0,0,0,1.1-4.45V43.9Zm-3,4.21a16.5,16.5,0,0,1-3,5.24C72.5,59.88,61.59,63.9,50,63.9s-22.5-4-28-10.55a15.13,15.13,0,0,1-3.9-9.45c0-11,14.33-20.06,32-20.06s32,9,32,20.06A11.64,11.64,0,0,1,81.16,48.11Z" />
      </svg>

    </StyledLoadingScreen>
  );
};

LoadingScreen.propTypes = {

};

export default LoadingScreen;
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0%;
    right: 0%;
    bottom: 0;
    border: solid 3px ${({ theme }) => theme.color.white};
    padding-bottom: ${({ theme }) => theme.spacing.xsmall};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    font-family: ${({ theme }) => theme.font.family.body};
    @media  screen and (min-width: 1001px) {
      padding-bottom: ${({ theme }) => theme.spacing.small};
      left: 20%;
      right: 20%;
    }
`

const PlayerContainer = styled(motion.button)`
    flex: 1;
    border: 0;
    position: relative;
    /* height: 17.5vw; 300px; */
    height: 100%;
    background-color: ${({ theme, selected }) => selected ? theme.color.white : 'transparent'};
    color: ${({ theme, selected }) => selected ? theme.color.primary : theme.color.white};
    cursor: pointer;
    outline:none;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Content} {
      transition: box-shadow 0.2s ease-out;
    }
    &:focus {
      ${Content} {
        outline: none;
        box-shadow: 0 0 0 2px white;
      }
    }

    @media  screen and (max-width: 1000px) {
      width: auto;
      flex: 0.5;
      /* height: 20vw; */
      margin: 5% 10px;
      padding: ${({ theme }) => theme.spacing.small};
      /* flex-direction: row-reverse; */
      align-items: center;
      justify-content: space-between;
      text-align: start;
    }
`;



const OverflowImage = styled(motion.img)`
  position: relative;
  z-index: 1;
  height: auto;
  width: 85%;
  margin-top: -44%;
  /* margin-bottom: 15%; */

  @media  screen and (min-width: 520px) {
    width: 64%;
    margin-top: -24%;
  }

  @media  screen and (min-width: 1001px) {
    height: auto;
    width: 100%;
    margin-top: 0;
    margin-bottom: 15%;

  }
`;


const PlayerCard = ({
  image,
  type,
  // width,
  // setView,
  // nextView,
  selected,
  onClick
}) => {
  const productAnim = {
    hidden: { opacity: 0, x: 0, y: 0, scale: 1 },
    visible: { opacity: 1, x: 0, y: 0, scale: 1 },
    hover: { opacity: 1, y: 8, rotate: 2, scale: 0.95 },
  }

  const imageAnim = {
    hidden: { opacity: 1, x: 0, y: 0, scale: 1 },
    visible: { opacity: 1, x: 0, y: 0, scale: 1 },
    hover: { opacity: 1, y: -10, rotate: -2, scale: 1.1 },
  }

  return (

    <PlayerContainer
      onClick={onClick}
      selected={selected}
      variants={productAnim}
      whileHover="hover"
    >
      <OverflowImage
        src={image && image}
        alt={type}
        variants={imageAnim}
        width="300"
        height="300"
      />
      <Content>
        <h3>{type.toUpperCase()}</h3>
      </Content>
    </PlayerContainer>

  );
};

PlayerCard.propTypes = {
  image: PropTypes.string,
  type: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PlayerCard;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import NavigationButton from '../NavigationButton';
import { Main } from '../helpComponents';
// import { motion } from 'framer-motion';
import { H2, H3, P } from '../helpComponents';
import __ from '../../localisation';

const StyledStoreSelectorView = styled.div`
    color: ${({ theme }) => theme.color.white};
    /* width: 100%;
    height: 100%; */
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: ${({ availableCities }) => availableCities.length > 0 ? "flex-start" : "center"};
    justify-content: center;
    text-align: center;
    padding: ${({ theme }) => theme.spacing.medium};

    @media  screen and (max-width: 1000px){
      height: auto;
      display: block;
    }

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      margin:  0 0 10px 0;
    }
`;

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    /* height: 100%; */
    width: 100%;

    /* @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      margin-bottom: ${({ theme }) => theme.spacing.medium};
      align-items: start;
    } */


    @media  screen and (max-width: 1000px) {
      display: flex;
      width: 100%;
      height: auto;

      /* @supports (display: grid) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      } */
    }

    & > * {
      margin: ${({ theme }) => theme.spacing.small};
      max-width: 20em;

      @media  screen and (min-width: 1001px) {
        margin: ${({ theme }) => theme.spacing.small};
        flex: 1 0 20em;
      }
    }
`;

const BackButton = styled.button`
  border: 0;
  background: 0;
  padding: 15px;
  color: white;
  cursor: pointer;
  &:active {
    outline: none
  }
`

const FakeHeight = styled.div`
  @media  screen and (min-width: 1001px) {
    height: 50px;
  }
`

const NoStoresBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StoreSelectorView = ({ setView, nextView, previousView, selectedCity, setSelectedCity, selectedStore, setSelectedStore, availableCities, availableStores, setFilteredStores, filteredStores, playertype, lang }) => {

  useEffect(() => {
    let filtered = availableStores.filter((store) => store.city.toLowerCase() === selectedCity);
    setFilteredStores(filtered);
    // eslint-disable-next-line
  }, [selectedCity])// Missing: availableStores && setFilteredStores

  const setCityAndFilterStores = (city) => {
    let filtered = availableStores.filter((store) => store.city.toLowerCase() === city);
    setFilteredStores(filtered);
    setSelectedCity(city);
  }

  return (
    <StyledStoreSelectorView availableCities={availableCities}>
      <NavigationButton left onClick={() => { selectedCity === false ? setView(previousView) : setSelectedCity(false) }} />
      {availableCities.length > 0 ?
        <Main>
          <H2 key={'h' + selectedCity}>{!selectedCity ? __('your_location', lang).toUpperCase() : __('select_store', lang).toUpperCase()}</H2>
          <P key={'p' + selectedCity}>{!selectedCity ? __('tell_us_where_', lang) : __('choose_your_preferred_store', lang)}</P>
          {!selectedCity ?
            <>
              <FakeHeight />
              <SelectWrapper key="cities">
                {availableCities.map(city => {
                  return (
                    <Button flex arrow onClick={() => setCityAndFilterStores(city)} key={city + 'available'}>{city}</Button>
                  )
                })}
              </SelectWrapper>
            </>
            :
            <>
              <H3>{selectedCity}
                <BackButton onClick={() => { setSelectedCity(false) }}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.3125 10.687L10.6875 1.31201" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M10.6875 10.687L1.3125 1.31201" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>
                </BackButton>
              </H3>
              <SelectWrapper key="stores">
                {filteredStores.map(store => {
                  // console.log('button-store', store)
                  return (
                    <Button
                      flex
                      key={store + 'filteredstores'}
                      arrow
                      onClick={() => {
                        setSelectedStore({ id: store.id, name: store.name, city: store.city });
                        setView(nextView); // Denna funkade nog inte innan / Jonas
                      }}
                      selected={selectedStore && selectedStore.name === store.name ? true : false}
                    >
                      {store.name}
                    </Button>
                  )
                })}
              </SelectWrapper>
            </>
          }
        </Main>
        :
        <Main>
          <H2>{__('no_stores_found', lang)}</H2>
        </Main>
      }
      {/* <NavigationButton onClick={() => setView(nextView)} visible={selectedStore ? true : false} /> */}

    </StyledStoreSelectorView>
  );
};

export default StoreSelectorView;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  flex: 1;
  box-sizing: border-box;

  @media  screen and (max-width: 1000px){
    width: 100%;
    height: 100%;
  }

  h2 + p {
    margin-bottom: 33px;
  }

  h2 + p + h3 {
    p {
      margin-bottom: 10px;
    }
  }
`;

export const H2 = ({ children }) => {
  return (
    <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {children}
    </motion.h2>
  )
}

export const H3 = ({ children }) => {
  return (
    <motion.h3 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
      {children}
    </motion.h3>
  )
}

export const P = ({ children }) => {
  return (
    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.1 }}>
      {children}
    </motion.p>
  )
}

// export const WidgetWrapper = styled.div`
// border: solid 1px white;
// width: 90%;
// height: 70%;
// min-height: 50vh;
// position: relative;
// box-sizing: border-box;

// @media  screen and (max-width: 1000px){
//   width: 100%;
//   min-height: 100vh;
//   height: auto;
// }
// `;

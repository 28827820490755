import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
// import { findAllByText } from '@testing-library/react';
// import { Link } from 'react-router-dom';
// import Icon from 'components/Icon';

/* DON'T COPY THIS TO OTHER REPOS, SPECIAL VERSION */
const ButtonButton = styled(motion.button)`
    font-family: ${({ theme }) => theme.font.family.body};
    font-size: ${({ theme, small }) => (small ? theme.font.size.bodySmall : '14px')};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    text-transform: capitalize;
    letter-spacing: ${({ theme }) => theme.font.spacing.specialSpacing};
    align-items: center;
    position: relative;
    border: none;
    border-radius: ${({ theme }) => theme.border.radius};
    background-color: ${({ theme, selected }) => selected ? theme.color.white : 'transparent'};
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme, selected }) => selected ? theme.color.primary : theme.color.white};
    overflow: hidden;
    /* Normalize */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    ::-moz-focus-inner {
      border: 0;
    }

    min-width: 195px;
    width: auto;
    flex-direction: row;
    justify-content: space-between;

    flex: ${({ flex }) => flex ? '1' : '0'};

    @media  screen and (max-width: 1000px){
      width: 100%;
    }

    /* for flex-layout */
    padding: ${({ theme, small }) => small ? `${theme.spacing.xsmall} ${theme.spacing.small}` : '13px 16px 10px 16px'};

    /* for arrow icon */
    & > svg {
      stroke: ${({ theme, selected }) => selected ? theme.color.primary : theme.color.white};
      position: absolute;
      right: ${({ theme, small }) => small ? ` ${theme.spacing.small}` : ` 16px`};
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    /* for focus state, transform for stacked wide buttons */
    transition:
      box-shadow ${`${({ theme }) => theme.time.base} ${({ theme }) => theme.easing.out}`},
      background-color ${({ theme }) => theme.time.base},
      transform ${`${({ theme }) => theme.time.base} ${({ theme }) => theme.easing.out}`},
      color ${`${({ theme }) => theme.time.fast} ${({ theme }) => theme.easing.out}`};

    /* for focus state */
    box-shadow:
      inset 0 0 0 ${({ theme, color }) => color ? '0px' : theme.border.width} ${({ theme }) => theme.color.white},
            0 0 0 0 rgba(255,255,255,0);

    /* Focus */
    &:focus {
      outline: none;
      box-shadow:
      inset 0 0 0 ${({ theme, color }) => color ? '0px' : theme.border.width} ${({ theme }) => theme.color.white},
        0 0 0 0 rgba(255,255,255,0);
    }

    /* Click Animation */
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 0.5em);
      left: calc(50% - 0.5em);
      width: 1em;
      height: 1em;
      background-color: ${({ theme }) => theme.color.primaryOpacity};
      opacity: 0;
      border-radius: 1em;
      transform: scale(1);
      transform-origin: 50% 50%;
    }

    @keyframes ripple {
      0% {
        transform: scale(0, 0);
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: scale(20, 20);
      }
    }

    &:focus::after {
      animation: ripple 1.5s ${({ theme }) => theme.easing.out};
    }
`;

const IconStyle = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const buttonAnim = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  hover: { opacity: 1 },
  active: { opacity: 1, scale: 0.95 },
}

const iconAnim = {
  hidden: { x: 0, rotate: -90 },
  visible: { x: 0, rotate: -90 },
  hover: { x: 5, rotate: -90 },
  active: { x: 5, rotate: -90 },
}
const Button = (props) => {

  const [loadingState, setLoadingState] = useState(false);

  // useEffect(() => {
  //   setLoading(props.loading)
  // }, [props.loading]);

  const {
    children,
    loading,
    loadingOnClick,
    loadingIcon,
    onClick,
    href,
    arrow,
    flex,
    selected,
    ...restProps
  } = props;

  return (
    <ButtonButton
      {...restProps}
      variants={buttonAnim}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      whileTap="active"
      flex={flex}
      selected={selected}
      onClick={onClick ? () => {
        if (loadingOnClick) {
          setLoadingState(true);
        }
        onClick();
      } : undefined}
    >
      {children}
      {(loading || loadingState)
        && <IconStyle>{loadingIcon}</IconStyle>
      }
      {arrow
        && (
          <motion.svg
            variants={iconAnim}
            width="12" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '15px' }}
          >
            <path d="M12.5 1.5L7 6.5L1.5 1.5" strokeWidth="2" strokeMiterlimit="10" />
          </motion.svg>
        )
      }
    </ButtonButton>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loadingOnClick: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loadingIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  small: PropTypes.bool,
  wide: PropTypes.bool,
};

Button.defaultProps = {
  loadingIcon: '[i]',
  disabled: undefined,
  onClick: undefined,
  href: undefined,
  loadingOnClick: undefined,
  loading: undefined,
  color: undefined,
  small: undefined,
  wide: undefined,
};

Button.displayName = 'Button';
export default Button;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ProductContainer = styled(motion.button)`
    position: relative;
    /* width: 25%;  */
    /*120px;*/
    /* height: 17.5vw; 300px; */
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    z-index:1;
    border: solid 3px ${({ theme }) => theme.color.white};
    margin: ${({ theme, small }) => small ? theme.spacing.small : `${theme.spacing.medium} ${theme.spacing.small} ${theme.spacing.small} ${theme.spacing.small}`};
    padding-bottom: 5px;
    background-color: ${({ theme, selected }) => selected ? theme.color.white : 'transparent'};
    color: ${({ theme, selected }) => selected ? theme.color.primary : theme.color.white};
    cursor: pointer;
    outline: none;
    font-family: ${({ theme }) => theme.font.family.body};

    /* padding-top: 10%; */
    /* width: 27%; */
    min-height: 100px;

    transition: box-shadow 0.2s ease-out;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px white;
    }

    @media  screen and (max-width: 1000px) {
      width: 100%;
      /* height: 20%; */
      margin: 5% 0;
      padding: ${({ theme }) => theme.spacing.xsmall};
      /* flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      text-align: start; */

      h3 {
        font-size: 12px;
      }
    }

    @supports (display: grid) {
      /* width: 100%;
      height: 100%; */
      margin: 0;
      margin-top: ${({ theme, small }) => small ? theme.spacing.small : theme.spacing.medium};
    }
`;


const OverflowImage = styled(motion.img)`
    z-index: 2;
    width: auto;
    height: ${({ small }) => small ? '110%' : '130%'};
    max-width: none !important;

    position: absolute;
    bottom: 0;
    /* margin-bottom: 15%; */
    margin-bottom: 30px;

    /* left: 50%;
    right: 50%;
    transform-origin: center; */

    @media  screen and (max-width: 1000px){
      /* width: 100%; */
      width: auto;
      height: ${({ small }) => small ? '90%' : '110%'};
      /* bottom: 5%; */
      margin-bottom: 25px;
    }
`;

const ProductCard = ({
  image,
  type,
  small,
  // width,
  // setView,
  // nextView,
  selected,
  onClick
}) => {

  const [loading, setLoading] = useState(true);
  useEffect(() => {

    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [])

  const productAnim = {
    hidden: { opacity: 0, x: 10, y: 0 },
    visible: { opacity: 1, x: 0, y: 0 },
    hover: { opacity: 1, y: 8, rotate: 2, scale: 0.95 },
  }

  const imageAnim = {
    hidden: { opacity: 1, x: 10, y: 0 },
    visible: { opacity: 1, x: 0, y: 0 },
    hover: { opacity: 1, y: -10, rotate: -8, scale: 1.2 },
  }

  return (

    <ProductContainer
      onClick={onClick}
      selected={selected}
      variants={productAnim}
      whileHover={!loading && "hover"}
      small={small}
    >
      <OverflowImage
        small={small}
        src={image && image}
        alt={type}
        variants={imageAnim}
        width="130"
        height="125"
      />
      <h3>{type.toUpperCase()}</h3>
    </ProductContainer>

  );
};

ProductCard.propTypes = {
  image: PropTypes.string,
  type: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

ProductCard.defaultProps = {

};

export default ProductCard;

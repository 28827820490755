export const DefaultTheme = {
  color: {
    /* Color rules */
    background: '#FFF',
    body: '#000',

    /* Colors */

    // Bauer Blue
    primary: '#067FC6',
    primaryContrast: '#FFF',
    // primaryHover: '#4C0DB9',
    // primaryHoverContrast: '#FFF',
    // primaryOpacity: 'rgba(95, 17, 232, 0.5)',

    // secondary: '#FFC1B4',
    // secondaryContrast: '#0A2A4F',
    // secondaryHover: '#CC9A90',
    // secondaryHoverContrast: '#0A2A4F',
    // secondaryOpacity: 'rgba(255, 193, 180, 0.5)',

    // accent: '#7BD75E',
    // accentContrast: '#0A2A4F',
    // accentHover: '#4c9e33',
    // accentHoverContrast: '#0A2A4F',
    // accentOpacity: 'rgba(123, 215, 94, 0.5)',

    /* Neutrals */
    white: '#FFF', whiteContrast: '#067FC6',
    // light: '#F8F8F8', lightContrast: '#0A2A4F',
    // medium: '#666666', mediumContrast: '#fff',
    // dark: '#0A2A4F', darkContrast: '#fff',
    // darkOpacity: 'rgba(1, 1, 1, 0.5)',
    black: '#000', blackContrast: '#fff',
    // disabled: 'silver',
  },
  spacing: {
    xsmall: '6px',
    small: '15px',
    medium: '30px',
    large: '50px',
    xlarge: '75px',
    xxlarge: '100px',
    xxxlarge: '150px',
    bodyPadding: '8%',
  },
  font: {
    family: {
      body: 'Neo Sans, monospace',
      heading: 'Neo Sans, sans-serif',
    },
    size: {
      base: '100%',
      baseMobile: '70%',
      body: '1em',
      bodySmall: '0.8em',
      bodyBig: '1.5em',
      headingLarge: '4.0625em',
      headingMedium: '3em',
      headingSmall: '2em',
      mobileSize: {
        body: '1.2em',
      },
    },
    height: {
      body: 1.2,
    },
    spacing: {
      spacing: 0,
      specialSpacing: '0.08em',
    },
    transform: {
      specialTransform: 'uppercase',
    },
    weight: {
      normal: 400,
      bold: 700,
    },
    get body() {
      return `normal normal 400 ${this.size.body}/${this.height.body} ${this.family.body}`
    },
  },
  mq: {
    xsmall: '390px',
    small: '576px',
    aboveSmall: '577px',
    underMedium: '767px',
    medium: '768px',
    aboveMedium: '769px',
    large: '1025px',
    aboveLarge: '1026px',
    upToNavBreak: '1075px',
    aboveNavBreak: '1076px',
    midScreens: '1200px',
    bigScreens: '1400px',
  },
  blockWidth: {
    small: '600px',
    medium: '900px',
  },
  border: {
    width: '2px',
    radius: '0px',
  },
  time: {
    base: '0.3s',
    fast: '0.15s',
    slow: '3s',
  },
  easing: {
    out: 'cubic-bezier(.2,.5,.3,1)',
    in: 'cubic-bezier(.6,0,.8,.5)',
  },
  shadows: {
    shadow2dp: 'rgba(20, 19, 42, 0.1) 0px 2px 10px -1px, rgba(20, 19, 42, 0.05) 0px 1px 1px 0px',
    shadow6dp: 'rgba(20, 19, 42, 0.2) 0px 4px 15px -3px, rgba(20, 19, 42, 0.10) 0px 3px 2px -1px',
    shadow12dp: 'rgba(20, 19, 42, 0.3) 0px 8px 20px -5px, rgba(20, 19, 42, 0.15) 0px 4px 3px -1px',
    shadow24dp: 'rgba(20, 19, 42, 0.6) 0px 14px 30px -7px, rgba(20, 19, 42, 0.25) 0px 6px 4px -2px',
  },
};

export default DefaultTheme;
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledNavigationButton = styled(motion.button)`
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: ${({ visible }) => visible ? 'unset' : 'none'};

    flex-direction: column;
    justify-content: flex-start;
    display: flex;

    position: absolute;
    z-index: 10;
    top:  23px;
    left: ${({ left }) => left ? '15px' : 'auto'};
    right: ${({ left }) => left ? 'auto' : '15px'};
    border: none;
    border-radius: 0;

    padding: 5px 6px 0 6px;

    transition: box-shadow 0.2s ease-out;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px white;
    }

    &:hover {
      outline: none;
      box-shadow: 0 0 0 2px white;
    }

    /* The icon */
    svg {
      stroke: ${({ theme }) => theme.color.white};
      transform: ${({ left }) => left ? 'rotate(90deg)' : 'rotate(-90deg)'};
      margin: auto;
      width: 30px;
    }

    /* @media  screen and (min-width: 1000px) {
      top: calc(50% - 24px);
    } */
`;


const NavigationButton = ({ onClick, left, visible }) => {
  const buttonAnim = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    hover: { opacity: 1 },
    active: { opacity: 1, scale: 0.95 },
  }

  const iconAnim = {
    hidden: { x: left ? 0 : -10, scale: left ? 1 : 1.5, rotate: left ? 0 : -10 },
    visible: { x: 0, scale: 1, rotate: 0 },
    hover: { x: left ? -5 : 5, scale: 1, rotate: 0 },
    active: { x: left ? -5 : 5, scale: 1, rotate: 0 },
  }

  return (
    <StyledNavigationButton
      variants={buttonAnim}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
      whileHover="hover"
      whileTap="active"
      onClick={onClick}
      left={left}
      visible={visible}
    >
      <motion.div
        variants={iconAnim}
      >
        <svg width="30" height="30" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 1.5L7 6.5L1.5 1.5" strokeWidth="1" strokeMiterlimit="10" />
        </svg>

      </motion.div>

    </StyledNavigationButton>
  );
};

NavigationButton.defaultProps = {
  visible: true
}

NavigationButton.propTypes = {
  onClick: PropTypes.func,
  left: PropTypes.bool,
  hide: PropTypes.bool,
  onlyMobile: PropTypes.bool,
  onlyDesktop: PropTypes.bool,
  absolute: PropTypes.bool,
};

export default NavigationButton;
import React, { useState, useEffect, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import DefaultTheme from './style/defaultTheme';
import DefaultGlobals from './style/defaultGlobals';
import StartView from './components/StartView';
import ProducttypeView from './components/ProducttypeView';
import StoreSelectorView from './components/StoreSelectorView';
import DateSelectorView from './components/DateSelectorView';
// // import SendView from './components/SendView';
import ConfirmationView from './components/ConfirmationView';
// import BackgroundLayer from '../src/components/BackgroundLayer';
import LoadingScreen from '../src/components/LoadingScreen';
import NextButton from './components/NextButton';

const InscreenView = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%; /* InScreen size is determend by the start view that is alwsays in the dom */
  display: flex;
  flex-direction: column;
`

const WrapStartView = styled(motion.div)`
  pointer-events: ${({ visible }) => visible ? 'unset' : 'none'};
`;

const Wrapper = styled.div`
  overflow: auto;
`;

function App({ config }) {
  const [currentView, setCurrentView] = useState("start");
  const [playertype, setPlayertype] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [submittedProducts, setSubmittedProducts] = useState([]);
  const [availableStores, setAvailableStores] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedCity, setSelectedCity] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointment, setAppointment] = useState({});
  const [interactedWith, setInteractedWith] = useState(false);
  // const [selectedTime, setSelectedTime] = useState(null);
  // const [name, setName] = useState(null);
  // const [email, setEmail] = useState(null);

  const [loading, setLoading] = useState(false);
  const wrapperEl = useRef(null);

  const { id, lang = 'en' } = config;

  // console.log(
  //   'player', playertype,
  //   'product', selectedProducts,
  //   'submitted products', submittedProducts,
  //   'city', selectedCity,
  //   'store', selectedStore,
  //   'date', selectedDate,
  //   'time', selectedTime,
  //   'name', name,
  //   'email', email
  // )

  // Fetch function for getting stores with selected products
  const getCitiesWithStores = (stores) => {
    let tempArray = [];

    stores.forEach(store => {
      let cityName = store.city.toLowerCase();
      tempArray.push(cityName);
    });

    const removeDuplicates = new Set(tempArray);
    const allAvailableCities = [...removeDuplicates];
    return allAvailableCities;
  }

  const getStoresAndMoveOn = async () => {
    if (selectedProducts !== submittedProducts) {
      setLoading(true)
      setSubmittedProducts(selectedProducts);



      let stores = await fetch(
        process.env.REACT_APP_ENV === 'production' ?
          'https://us-central1-bauer-scheduler-production.cloudfunctions.net/customer/stores'
          : 'https://us-central1-bauer-scheduler.cloudfunctions.net/customer/stores',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            uid: id,
            products: selectedProducts,
          })
        })
        .then(response => response.json())
        .catch((error) => {
          console.error('Error:', error);
        });

      console.log("returned Stores", stores)

      if (stores === undefined) {
        setLoading(false);
        setCurrentView("storeselector");
        setAvailableCities([]);
      }

      if (stores) {
        setAvailableStores(stores);
        let cities = await getCitiesWithStores(stores);
        setAvailableCities(cities);

        setLoading(false);
        setCurrentView("storeselector");
      }

    }
    else {
      setCurrentView("storeselector");
    }
  }

  // useEffect(() => {
  //   const loader = document.getElementById('loading-animation');
  //   // console.log("use effect running, this should remove loading")
  //   setTimeout(() => {
  //     loader.remove();
  //   }, 600);
  // }, [])

  // let time = new Date("2020-07-08T07:00:00+02:00");
  // const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', time: 'numeric' };
  // const timeOptions = { hour: '2-digit', minute: '2-digit' };
  // let locales = undefined;
  // let timezone = undefined;
  // console.log(time.toLocaleDateString(locales, dateOptions));
  // console.log(time.toLocaleTimeString(timezone, timeOptions));
  useEffect(() => {
    if (currentView !== 'start') setInteractedWith(true);
    console.log('interactedWith', interactedWith, currentView);
    if (!wrapperEl.current) return;

    const el = wrapperEl.current;

    // If the widget container has been scrolled into, scroll to top of it
    el.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    // If the parent window has been scrolled, scroll up to where the widget starts
    interactedWith && window.scrollTo({
      top: el.getBoundingClientRect().top + el.ownerDocument.defaultView.pageYOffset,
      left: 0,
      behavior: 'smooth'
    });
  }, [wrapperEl, currentView, interactedWith]);

  const views = {
    start: {
      id: 'start',
      view: (
        <StartView
          lang={lang}
          setView={setCurrentView}
          nextView="producttype"
          setPlayertype={setPlayertype}
        />
      ),
      nextView: "producttype",
      prevView: false,
    },
    producttype: {
      id: 'producttype',
      view: (
        <ProducttypeView
          uid={id}
          lang={lang}
          setView={setCurrentView}
          nextView="storeselector"
          previousView="start"
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          playertype={playertype}
          submittedProducts={submittedProducts}
          setSubmittedProducts={setSubmittedProducts}
          setAvailableCities={setAvailableCities}
          setAvailableStores={setAvailableStores}
          availableStores={availableStores}
          availableCities={availableCities}
          setLoading={setLoading}
        />
      ),
      nextView: "storeselector",
      prevView: "start",
    },

    storeselector: {
      id: 'storeselector',
      view: (
        <StoreSelectorView
          setView={setCurrentView}
          lang={lang}
          nextView="dateselector"
          previousView="producttype"
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
          availableCities={availableCities}
          availableStores={availableStores}
          filteredStores={filteredStores}
          setFilteredStores={setFilteredStores}
          playertype={playertype}
        />
      ),
      nextView: "dateselector",
      previousView: "producttype",
    },
    dateselector: {
      id: 'dateselector',
      view: (
        <DateSelectorView
          setView={setCurrentView}
          lang={lang}
          nextView="confirmation"
          previousView="storeselector"
          selectedDate={selectedDate}
          setSelectedDay={setSelectedDate}
          selectedStore={selectedStore}
          selectedProducts={submittedProducts}
          setAppointment={setAppointment}
        />
      ),
      nextView: "confirmation",
      previousView: "storeselector"
    },
    confirmation: {
      id: 'confirmation',
      view: (
        <ConfirmationView
          setView={setCurrentView}
          lang={lang}
          previousView="dateselector"
          appointment={appointment}
        />
      ),
      nextView: false,
      previousView: "dateselector"
    },
  };

  // const viewsIndex = [
  //   views.start,
  //   views.producttype,
  //   views.storeselector,
  //   views.dateselector,
  //   views.confirmation,
  // ];

  // console.log('Current view:', views[currentView].id);
  // console.log('prev view:', views[currentView].prevView);
  // console.log('next view:', views[currentView].nextView);

  // const currentViewId = views[currentView].id;
  // const prevViewId = views[currentView].prevView ? views[views[currentView].prevView].id : false;
  // const nextViewId = views[currentView].nextView ? views[views[currentView].nextView].id : false;
  const viewAnim = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Wrapper ref={wrapperEl}>
        <DefaultGlobals />
        <LoadingScreen loading={loading ? loading : undefined} />

        <motion.div animate={{ opacity: loading ? 0.5 : 1 }} className="BauerBookingWidget">
          {/* <BackgroundLayer /> */}
          {/* <AnimateSharedLayout> */}
          {currentView !== 'start' && (
            <InscreenView>
              {views[currentView].view}
            </InscreenView>
          )}

          <WrapStartView
            visible={currentView === 'start'}
            variants={viewAnim}
            initial="hidden"
            animate={currentView === 'start' ? 'visible' : 'hidden'}
          >
            {views['start'].view}
          </WrapStartView>

          {/* {prevViewId !== 'start' && prevViewId && (
              <OffscreenView layoutId={prevViewId}>
                {views[prevViewId].view}
              </OffscreenView>
            )}
            {nextViewId && (
              <OffscreenView layoutId={nextViewId}>
                {views[nextViewId].view}
              </OffscreenView>
            )} */}
          {/* </AnimateSharedLayout> */}

        </motion.div>
        <NextButton lang={lang} onClick={() => getStoresAndMoveOn()} visible={currentView === "producttype" && selectedProducts && selectedProducts.length > 0 ? true : false} />
      </Wrapper>
    </ThemeProvider>
  );
}

// In dev change this id if you wish to try another user
// For prod: Remove this ID
App.defaultProps = {
  config: {
    // en
    // id: "LP0xpTxoJghjJiGo4QLf73HEcBH3",
    //ru
    id: "dQrsdfdNEOhdMP1pCQWGB7hdOVA2",
    lang: 'en'
  }
}

export default App;

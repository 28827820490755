import React from "react";
import Script from "react-load-script";
import styled from "styled-components";
import { motion } from "framer-motion";
import __ from "../../localisation";

const StyledSchedule = styled(motion.div)`
  height: 100%;
  width: 100%;

  #availability {
    height: 100%;
    max-height: 370px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1000px) {
      max-height: none;
    }
  }

  .onsched-container {
    height: 100%;
    background-color: transparent;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
      width: 100%;
      margin: 0;
      max-width: 100%;
    }
    * {
      border: 0;
    }
    input,
    textarea {
      border: 1px solid ${({ theme }) => theme.color.primary};
      box-shadow: none;
      border-radius: 0;
    }
    [type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 24px;
      height: 24px;
      border: solid 1px ${({ theme }) => theme.color.primary};
      margin-right: 8px;
      position: relative;
      flex: 1 0 24px;
      display: inline-block;
    }
    [type="checkbox"]::before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgY2xhc3M9Imljb24tY2hlY2tib3giIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmVyc2lvbj0iMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNCAxNGw4IDdMMjQgNyIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+PC9zdmc+);
      background-size: cover;
      position: absolute;
      top: 3px;
      left: 3px;
      opacity: 0;
      transform: translateX(2px);
      transition: opacity 0.3s, transform 0.3s;
    }
    [type="checkbox"]:checked::before {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .onsched-row {
    height: 100%;
    width: 100%;
    padding: ${({ theme }) => theme.spacing.small};
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: 1000px) {
      padding: 0;
      flex-direction: column;
    }
  }

  .onsched-col {
    height: 349px;
    @media (min-width: 1001px) {
      height: 320px;
    }
  }
  /* DATE */
  /* Styling for date column */
  div.onsched-col:first-of-type {
    /* height: 100%; */
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 8px;
    margin: 0 ${({ theme }) => theme.spacing.small};
    padding: calc(${({ theme }) => theme.spacing.small} - 1px);

    @media screen and (min-width: 331px) and (max-width: 1000px) {
      /* margin: ${({ theme }) => theme.spacing.small}; */
      padding: ${({ theme }) => theme.spacing.small};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      /* max-height: 90vw; */
      /* width: 100%; */
    }
    @media screen and (max-width: 330px) {
      margin: 0;
      width: 100%;
    }
    @media screen and (max-width: 1000px) {
      margin-top: ${({ theme }) => theme.spacing.small};
    }

    /* Appointment info */
    .onsched-available-times-header {
      display: none;
    }

    .onsched-calendar-title,
    button.month-prev,
    button.month-next {
      color: ${({ theme }) => theme.color.primary};
      border: none;
    }

    .onsched-calendar-grid {
      margin: 0;

      /* All dates */
      button.day {
        border-radius: 0;
        border: 1px solid ${({ theme }) => theme.color.neutral};
        color: ${({ theme }) => theme.color.neutral};
        margin: 4px;
        padding-top: 3px;
      }

      /* Available dates */
      button:not([disabled="disabled"]) {
        border: 1px solid ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.primary};
      }

      /* Selected day */
      button.day.selected {
        background-color: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.white};
      }
    }

    .onsched-timezone {
      display: none;
    }
  }

  /* TIME */
  /* Styling for time column */
  div.onsched-col:last-of-type {
    /* height: 100%; */
    margin: 0 ${({ theme }) => theme.spacing.small};
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.color.white};
    /* padding: ${({ theme }) => theme.spacing.small}; */
    overflow: hidden;
    /* height: 320px; */

    @media (max-width: 1000px) {
      /* height: 349px; */
      margin: ${({ theme }) => theme.spacing.small};
      max-height: 70vw;
      width: auto;
    }

    th {
      color: ${({ theme }) => theme.color.white};
      padding: 0;
      display: none;
    }

    .onsched-available-times {
      width: 100%;
      height: 100%;
      /* overflow-y: scroll; */
      overflow: auto;
      padding: ${({ theme }) => theme.spacing.small};
      margin: 0;

      @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
    }

    .onsched-table {
      width: 100%;
      margin-bottom: ${({ theme }) => theme.spacing.small};
    }

    /* .onsched-table:empty {
        &:after {
          content: "No times available on this date",
        }
      } */

    td {
      padding: 0;
    }

    .time-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .hoverable {
      border: 2px solid ${({ theme }) => theme.color.white};
      background-color: transparent;
      border-radius: 0;
      color: ${({ theme }) => theme.color.white};
      margin: ${({ theme }) => theme.spacing.xsmall};

      @media screen and (max-width: 1000px) {
      }

      /* span.ampm {
          display: none;
        } */
    }
  }

  .onsched-table tr {
    border: 0;
  }

  /* POP-UP STYLING*/
  div.onsched-popup {
    box-sizing: border-box;

    .onsched-popup-header {
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.white};

      i.fa.fa-clock {
        display: none;
      }
    }

    label {
      color: ${({ theme }) => theme.color.primary};
      font-weight: bold;
      text-align: left;
      display: flex;
      align-items: center;
    }

    input:focus {
      outline-color: ${({ theme }) => theme.color.primary};
    }

    .onsched-booking-summary {
      display: none;
    }

    /* .onsched-popup-content {
      } */

    .onsched-form.booking-form button[type="submit"] {
      background-color: ${({ theme }) => theme.color.primary};
    }

    .onsched-form-privacy-fields {
      & > .onsched-form-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > .onsched-form-col {
          margin: 0;
        }
      }

      #acceptPrivacyTerms {
        margin-bottom: 3px;
      }
    }

    .onsched-form-col button {
      padding: ${({ theme }) => theme.spacing.small};
    }
  }

  .onsched-booking-confirmation-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 1000px) {
      position: relative;
    }

    & > * {
      text-align: left;
      font-weight: bold;
    }

    p {
      font-weight: bold;
    }
    p:first-of-type,
    p:last-of-type {
      font-weight: normal;
    }
  }
  .onsched-no-available-times {
    color: white;
    .fa {
      color: white !important;
    }
  }

  a.onsched-chip {
    color: white !important;
    flex: 1 0
      calc(
        50% - ${({ theme }) => theme.spacing.xsmall} -
          ${({ theme }) => theme.spacing.xsmall}
      );
    max-width: 40%; /* For IE :D */
    max-width: calc(
      50% - ${({ theme }) => theme.spacing.xsmall} -
        ${({ theme }) => theme.spacing.xsmall}
    );
    text-align: center;
  }

  .onsched-alert {
    margin-left: ${({ theme }) => theme.spacing.small};
    margin-right: ${({ theme }) => theme.spacing.small};
    border-radius: 0;
  }

  .onsched-close-btn {
    padding: 20px;
    top: -5px;
    right: 0;
  }
`;

const Scheduler = ({
  storeId,
  selectedProducts,
  setAppointment,
  setView,
  nextView,
  lang,
}) => {
  // console.log("products in schedule", selectedProducts)

  let products = selectedProducts.join();
  // console.log(products);

  /* eslint-disable */
  const OnschedLoaded = () => {
    const PROD = process.env.REACT_APP_ENV === "production";
    const locale = navigator.language || navigator.userLanguage;

    var onsched = OnSched(
      PROD ? "client1595722791" : "clientsb1591286181",
      PROD ? "live" : "sbox",
      { locale }
    );

    var elements = onsched.elements();
    var now = new Date();
    var tzOffset = -now.getTimezoneOffset();
    var availabilityParams = {
      locationId: storeId,
      serviceId: PROD ? 4902 : 50463,
      tzOffset: tzOffset,
      appointmentBM: { customFields: { field1: products } },
    };
    // Use privacy fields to force a customer to accept terms and/or view the privacy policy
    var availabilityOptions = {
      privacyFields: {
        checkboxLabel: __("by_booking_", lang),
        linkText: __("view_terms_and_policy", lang),
        linkUrl:
          "https://www.bauer.com/en-US/bauer-support/policies/privacy-policy.html",
        linkTitle: __("view_in_new_tab", lang),
      },
      bookingConfirmation: { suppressUI: true },
    };
    var availability = elements.create(
      "availability",
      availabilityParams,
      availabilityOptions
    );

    availability.mount("availability");
    var elAvailability = document.getElementById("availability");
    elAvailability.addEventListener("bookingConfirmation", function (e) {
      // console.log("BOOKING CONFIRMATION EVENT")
      // console.log(e.detail);
      setAppointment(e.detail);
      setView(nextView);
      // use this event to render your own html for the booking confirmation
      // e.detail is the API response data from PUT /appointments{id}/book endpoint
      // response data has all the relevant details to display in the confirmation
    });
  };
  /* eslint-enable */

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <StyledSchedule initial="hidden" animate="visible" variants={variants}>
      <div id="availability"></div>
      <Script
        url="https://js.onsched.com/1.0.0/"
        onCreate={() => {
          console.log("creating");
        }}
        onError={() => {
          console.error("error");
        }}
        onLoad={OnschedLoaded}
      />
    </StyledSchedule>
  );
};

export default Scheduler;

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { motion } from 'framer-motion';
// import Button from '../Button';
import PlayerCard from '../PlayerCard';
import apienv from '../../apienv';
import { H2, P } from '../helpComponents';
import Tilt from 'react-tilt';
import __ from '../../localisation';

const StyledStartView = styled.div`
    color: ${({ theme }) => theme.color.white};
    /* width: 100%;
    height: 100%; */
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.bodyPadding};
    position: relative;

    @media  screen and (max-width: 1000px){
      flex-direction: column;
      justify-content: flex-start;
    }
`;

const Welcome = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: ${({ theme }) => theme.spacing.large};

    @media  screen and (max-width: 1000px){
      height: auto;
      width: 100%;
    }
`;

const Continue = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-left: -4%; /* half of bodyPadding */
    margin-right: -4%; /* half of bodyPadding */

    @media  screen and (max-width: 1000px){
      width: auto;
      /* flex-direction: column; */
      height: auto;
      align-items: stretch;
    }
`;

const Underline = styled.div`
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.color.white};
    margin: ${({ theme }) => theme.spacing.small} 0 ${({ theme }) => theme.spacing.medium} 0;
`;

const StartView = ({ setView, nextView, setPlayertype, lang }) => {

  const clickFunction = (type) => {
    setPlayertype(type);
    setView(nextView);
  };

  return (
    <StyledStartView>
      <Welcome>
        <img src={`${apienv.imgStorage}/images/new-logo.svg`} alt="logo" />
        <Underline />
        <H2>{__('book_a_fitting_', lang).toUpperCase()}</H2>
        <P>{__('book_a_private_', lang)}</P>
        <P><strong>{__('first_question_', lang)}</strong></P>
      </Welcome>
      <Continue>
        <Tilt className="Tilt" options={{ max: 10 }}>
          <PlayerCard type={__('player', lang)} image={`${apienv.imgStorage}/images/player.png`} onClick={() => clickFunction("player")} />
        </Tilt>
        <Tilt className="Tilt" options={{ max: 10 }}>
          <PlayerCard type={__('goalie', lang)} image={`${apienv.imgStorage}/images/goalie.png`} onClick={() => clickFunction("goalie")} />
        </Tilt>
      </Continue>
    </StyledStartView >
  );
};

export default StartView;
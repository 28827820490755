import React from 'react';
import styled from 'styled-components';
import ProductCard from '../ProductCard';
import NavigationButton from '../NavigationButton';
import { Main, H2, P } from '../helpComponents';
import apienv from '../../apienv';
import { motion } from 'framer-motion';
import __ from '../../localisation';

const StyledProducttypeView = styled.div`
    color: ${({ theme }) => theme.color.white};
    /* width: 100%;
    height: 100%; */
    flex: 1;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
    padding: ${({ theme }) => theme.spacing.medium};

    @media  screen and (max-width: 1000px){
      flex-direction: column;
      justify-content: flex-start;
      /* height: auto; */
    }
`;

const ProductWrapper = styled.div`
  width: 80%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow:3;

  @media  screen and (max-width: 1000px){
    flex-direction: column;
    justify-content: flex-start;
    /* height: auto; */
  }

  @supports (display: grid){
    width: 100%;
  }
`;

const ProductRow = styled(motion.div)`
  width: 80%;
  /* height: auto; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.small};

  flex-wrap: wrap;

  @supports (display: grid){
    display: grid;
    grid-template-columns: ${({ wide }) => wide ? 'repeat(3, minmax(auto, 12em))' : 'repeat(2, minmax(auto, 12em))'};
    grid-gap: ${({ wide }) => wide ? '30px' : '60px'};
    padding: 0;
  }

  @media  screen and (max-width: 1000px){
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      height: auto;
      margin-bottom: ${({ theme }) => theme.spacing.medium};

      @supports (display: grid){
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0px 20px;
      }
    }

`;


const ProducttypeView = ({
  setView,
  nextView,
  previousView,
  playertype,
  selectedProducts,
  setSelectedProducts,
  submittedProducts,
  setSubmittedProducts,
  setAvailableStores,
  availableStores,
  setAvailableCities,
  availableCities,
  setLoading,
  uid,
  lang
}) => {

  // map through player or goalie array depending on playertype
  const playerProducts = [
    {
      id: 'mbskates',
      title: __('my_bauer_skates', lang),
      img: `${apienv.imgStorage}/images/my-bauer-skates.png`,
    },
    {
      id: 'rskates',
      title: __('regular_skates', lang),
      img: `${apienv.imgStorage}/images/regular-skates.png`,
    },
    {
      id: 'mbstick',
      title: __('my_bauer_stick', lang),
      img: `${apienv.imgStorage}/images/my-bauer-stick.png`,
    },
    {
      id: 'rstick',
      title: __('regular_stick', lang),
      img: `${apienv.imgStorage}/images/regular-stick.png`,
    },
    {
      id: 'helmet',
      title: __('helmet', lang),
      img: `${apienv.imgStorage}/images/helmet.png`,
    },
    {
      id: 'protective',
      title: __('protective', lang),
      img: `${apienv.imgStorage}/images/protective.png`,
    },
  ];

  const goalieProducts = [
    {
      id: 'mbgoalie',
      title: __('my_bauer_goalie', lang),
      img: `${apienv.imgStorage}/images/my-bauer-goalie.png`,
    },
    {
      id: 'rgoalie',
      title: __('regular_goalie', lang),
      img: `${apienv.imgStorage}/images/regular-goalie.png`,
    }
  ];

  const updateSelectedProducts = (product) => {
    let tempArray = [];

    if (selectedProducts.includes(product)) {
      tempArray = selectedProducts.filter(item => item !== product);
      setSelectedProducts(tempArray);
    }
    else {
      selectedProducts.forEach(selectedProduct => {
        tempArray.push(selectedProduct)
      });
      tempArray.push(product)
      setSelectedProducts(tempArray);
    }

  }

  let products = playerProducts;
  if (playertype === 'player') {
    products = playerProducts;
  }
  else {
    products = goalieProducts;
  }

  // create onClick-function that fetches stores, see in App.js

  // const getCitiesWithStores = (stores) => {
  //   let tempArray = [];

  //   stores.forEach(store => {
  //     let cityName = store.city.toLowerCase();
  //     tempArray.push(cityName);
  //   });

  //   const removeDuplicates = new Set(tempArray);
  //   const allAvailableCities = [...removeDuplicates];
  //   return allAvailableCities;
  // }

  // const getStoresAndMoveOn = async () => {
  //   if (selectedProducts !== submittedProducts) {
  //     setLoading(true)
  //     setSubmittedProducts(selectedProducts);

  //     let stores = await fetch(`https://us-central1-bauer-scheduler.cloudfunctions.net/customer/stores`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Accept': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           uid,
  //           products: submittedProducts,
  //         })
  //       })
  //       .then(response => response.json())

  //     setAvailableStores(stores);
  //     let cities = await getCitiesWithStores(stores);
  //     setAvailableCities(cities);

  //     setLoading(false);
  //     setView(nextView);
  //   }
  //   else {
  //     setView(nextView);
  //   }
  // }

  const list = {
    visible: {
      opacity: 1,
      // x: 0,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.04,
      },
    },
    hidden: {
      opacity: 0,
      // x: 100,
      transition: {
        when: "afterChildren",
      },
    },
  }

  console.log(playertype);

  return (
    <StyledProducttypeView>
      <NavigationButton left onClick={() => setView(previousView, setSelectedProducts([]))} />
      <Main>
        <H2>
          {__('select_product_type', lang)}
        </H2>
        <P>{__('you_can_choose_more_than_one', lang)}</P>
        <ProductWrapper>
          <ProductRow
            initial="hidden"
            animate="visible"
            variants={list}
            wide={playertype === 'player'}
          >
            {products.map(product => {
              return (
                <ProductCard
                  small={playertype === 'player'}
                  key={product.id}
                  selected={selectedProducts.includes(product.id) ? true : false}
                  type={product.title}
                  image={product.img}
                  onClick={() => updateSelectedProducts(product.id)}
                />
              )
            })}
          </ProductRow>
        </ProductWrapper>
      </Main>
      {/* <NavigationButton onClick={() => getStoresAndMoveOn()} visible={selectedProducts && selectedProducts.length > 0 ? true : false} /> */}
    </StyledProducttypeView>
  );
};

ProducttypeView.propTypes = {

};

export default ProducttypeView;
export default (key, lang = 'en') => {

// Default for old widets

  const langObj = {
    "next": {
      "en": "Next",
      "ru": "Дальше"
    },
    "book_a_fitting_": {
      "en": "Book a fitting session with us",
      "ru": "Запишитесь на примерку у нас"
    },
    "book_a_private_": {
      "en": "Book a private fitting session with one of our in-store Bauer Fit Experts, and find the perfect Bauer equipment for you, suited for your measurements, requests and skill level. Welcome in!",
      "ru": "Запишись на личную примерку со специалистом Bauer Fit и подбери коньки, которые подходят именно тебе. Добро пожаловать!"
    },
    "first_question_": {
      "en": "First question: are you a player or goalie?",
      "ru": "Первый вопрос: Ты  полевой игрок или вратарь?"
    },
    "player": {
      "en": "Player",
      "ru": "Полевой игрок"
    },
    "goalie": {
      "en": "Goalie",
      "ru": "Вратарь"
    },
    "my_bauer_skates": {
      "en": "My Bauer Skates",
      "ru": "Коньки My Bauer"
    },
    "regular_skates": {
      "en": "Regular Skates",
      "ru": "Коньки"
    },
    "my_bauer_stick": {
      "en": "My Bauer Stick",
      "ru": "Клюшка My Bauer"
    },
    "regular_stick": {
      "en": "Regular Stick",
      "ru": "Клюшка"
    },
    "helmet": {
      "en": "Helmet",
      "ru": "Шлем"
    },
    "protective": {
      "en": "Protective",
      "ru": "Защита"
    },
    "my_bauer_goalie": {
      "en": "my_bauer_goalie",
      "ru": "Вратарка My Bauer"
    },
    "regular_goalie": {
      "en": "Regular Goalie",
      "ru": "Вратарка"
    },
    "select_product_type": {
      "en": "Select product type",
      "ru": "Выбери тип продукта"
    },
    "you_can_choose_more_than_one": {
      "en": "You can choose more than one",
      "ru": "Можешь выбрать более чем один"
    },
    "your_location": {
      "en": "Your location",
      "ru": "Местонахождение"
    },
    "tell_us_where_": {
      "en": "Tell us where you want your fitting to take place",
      "ru": "Выбери куда ты хочешь приехать на примерку"
    },
    "select_store": {
      "en": "Select store",
      "ru": "Выбери магазин"
    },
    "choose_your_preferred_store": {
      "en": "Choose your preferred store",
      "ru": "Выбери желаемый магазин"
    },
    "available_dates": {
      "en": "Available dates",
      "ru": "Доступные даты"
    },
    "january": {
      "en": "January",
      "ru": "Январь"
    },
    "february": {
      "en": "February",
      "ru": "Февраль"
    },
    "march": {
      "en": "March",
      "ru": "Март"
    },
    "april": {
      "en": "April",
      "ru": "Апрель"
    },
    "may": {
      "en": "May",
      "ru": "Май"
    },
    "june": {
      "en": "June",
      "ru": "Июнь"
    },
    "july": {
      "en": "July",
      "ru": "Июль"
    },
    "august": {
      "en": "August",
      "ru": "Август"
    },
    "september": {
      "en": "September",
      "ru": "Сентябрь"
    },
    "october": {
      "en": "October",
      "ru": "Октябрь"
    },
    "november": {
      "en": "November",
      "ru": "Ноябрь"
    },
    "december": {
      "en": "December",
      "ru": "Декабрь"
    },
    "mon": {
      "en": "Mon",
      "ru": "Понедельник"
    },
    "tue": {
      "en": "Tue",
      "ru": "Вторник"
    },
    "wed": {
      "en": "Wed",
      "ru": "Среда"
    },
    "thu": {
      "en": "Thu",
      "ru": "Четверг"
    },
    "fri": {
      "en": "Fri",
      "ru": "Пятница"
    },
    "sat": {
      "en": "Sat",
      "ru": "Суббота"
    },
    "sun": {
      "en": "Sun",
      "ru": "Воскресенье"
    },
    "by_booking_": {
      "en": "By booking, you accept our Terms of use & our Privacy Policy which state that we will never share your information to third parties and that you can withdraw consent at any time.",
      "ru": "При подтверждении записи вы принимаете условия пользовательского соглашения, в которых прописано, что мы никогда не передаем личную информацию третьим лицам. Вы так же имеете возможность отозвать свое согласие в любое время."
    },
    "view_terms_and_policy": {
      "en": "View Terms & Policy",
      "ru": "Просмотр пользовательского соглашения"
    },
    "complete_booking": {
      "en": "Complete booking",
      "ru": "Завершить запись"
    },
    "thats_it_": {
      "en": "That's it. See you at the fitting session.",
      "ru": "Все готово! Увидимся на примерке."
    },
    "here_is_a_summary_": {
      "en": "Here is a summary. A confirmation e-mail has also been sent to your inbox.",
      "ru": "Ниже краткая сводка. На ваш почтовый ящик также было отправлено электронное письмо с подтверждением."
    },
    "summary": {
      "en": "Summary",
      "ru": "Краткая сводка"
    },
    "fitting_session": {
      "en": "Fitting Session",
      "ru": "Примерка"
    },
    "location": {
      "en": "Location",
      "ru": "Место"
    },
    "day": {
      "en": "Day",
      "ru": "День"
    },
    "time": {
      "en": "Time",
      "ru": "Время"
    },
    "cancel": {
      "en": "Cancel",
      "ru": "Отменить"
    },
    "no_stores_found": {
      "en": "sorry couldn't find any store with the selected gear.",
      "ru": "Извините, мы не нашли подходящий магазин по выбранным позициям"
    },
    "view_in_new_tab": {
      "en": "View in new tab",
      "ru": "Открыть в новой вкладке"
    }
  };

  if (!langObj[key]) {
    console.error('Didnt find language');
    return 'NO LANGUAGE FOUND!';
  }

  if (!langObj[key][lang]) {
    console.error('Didnt find key');
    return 'NO KEY FOUND!';
  }

return langObj[key][lang]

}
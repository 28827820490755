import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavigationButton from '../NavigationButton';
import { Main, H2 } from '../helpComponents';
import Scheduler from '../Scheduler/Scheduler';
import __ from '../../localisation';

const StyledDateSelectorView = styled.div`
    color: ${({ theme }) => theme.color.white};
    /* width: 100%;
    height: 100%; */
    flex: 1;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
    padding: ${({ theme }) => theme.spacing.medium};

    @media  screen and (max-width: 1000px){
      flex-direction: column;
      justify-content: flex-start;
      height: auto;
      padding: ${({ theme }) => theme.spacing.medium} 0;
    }
`;


const DateSelectorView = ({ setView, nextView, previousView, selectedStore, selectedProducts, setAppointment, lang }) => {

  let storeId = selectedStore ? selectedStore.id : null;

  return (
    <StyledDateSelectorView>
      <NavigationButton left onClick={() => setView(previousView)} />
      <Main>
        <H2>{__('available_dates', lang).toUpperCase()}</H2>
        <Scheduler lang={lang} storeId={storeId} selectedProducts={selectedProducts} setAppointment={setAppointment} setView={setView} nextView={nextView} />
      </Main>
      {/* <NavigationButton hide onClick={() => setView(nextView)} /> */}
    </StyledDateSelectorView>
  );
};

DateSelectorView.defaultProps = {
  selectedStore: { id: null },
}

// DateSelectorView.propTypes = {

// };

export default DateSelectorView;
import React from 'react';
import styled from 'styled-components';
import { Main, H2, P } from '../helpComponents';
import { motion } from 'framer-motion';
import __ from '../../localisation';

const StyledConfirmationView = styled(motion.div)`
    color: ${({ theme }) => theme.color.white};
    /* width: 100%;
    height: 100%; */
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.xsmall}`};
`;

const BookingInformation = styled(motion.div)`
  /* min-width: 400px; */
  width: 100%;
  max-width: 40em;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing.small};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 1000px) {
    padding: ${({ theme }) => theme.spacing.medium};
  }

  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin: ${({ theme }) => theme.spacing.xsmall} !important;
  }
`;

const InfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  p {
    font-size: 14px;
    line-height: 17px;
    margin: ${({ theme }) => theme.spacing.xsmall};
    font-weight: bold;
  }

  & > p:first-of-type {
    font-weight: normal;
  }

  span {
    display: flex;
  }
`;


const ConfirmationView = ({ appointment, lang }) => {

  const getProductName = (product) => {
    let name;
    switch (product) {
      case "mbstick":
        name = __('my_bauer_stick', lang);
        break;
      case "rstick":
        name = __('regular_stick', lang);
        break;
      case "mbskates":
        name = __('my_bauer_skates', lang);
        break;
      case "rskates":
        name = __('regular_skates', lang);
        break;
      case "helmet":
        name = __('helmet', lang);
        break;
      case "protective":
        name = __('protective', lang);
        break;
      case "mbgoalie":
        name = __('my_bauer_goalie', lang);
        break;
      case "rgoalie":
        name = __('regular_goalie', lang);
        break;

      default:
        break;
    }
    return name;
  };

  // Display products properly
  let products = appointment.customFields && appointment.customFields.field1.value ? appointment.customFields.field1.value.split(",") : [];
  let productNames = products.map(product => {
    return (
      getProductName(product)
    )
  }
  )
  let bookedProducts = productNames.join(', ');

  let time = new Date(appointment.startDateTime);
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', time: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };
  let locales = undefined;
  let timezone = undefined;
  // console.log(time.toLocaleDateString(locales, dateOptions));
  // console.log(time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timezone: timezone }));

  const animWrap = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const animInfo = {
    hidden: { y: 10, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <StyledConfirmationView variants={animWrap} initial="hidden" animate="visible">
      <Main>
        <H2>{__('thats_it_', lang).toUpperCase()}</H2>
        <P>{__('here_is_a_summary_', lang)}</P>
        <BookingInformation variants={animInfo}>
          <h4>{__('summary', lang)}</h4>
          <InfoRow>
            <p>{__('fitting_session', lang)}:</p>
            <p>{bookedProducts}</p>
          </InfoRow>
          <InfoRow>
            <p>{__('location', lang)}:</p>
            <p>{appointment.businessName}</p>
          </InfoRow>
          <InfoRow>
            <p>{__('day', lang)}:</p>
            <p>{time.toLocaleDateString(locales, dateOptions)}</p>
          </InfoRow>
          <InfoRow>
            <p>{__('time', lang)}:</p>
            <p>{time.toLocaleTimeString(timezone, timeOptions)}</p>
          </InfoRow>
        </BookingInformation>
      </Main>
    </StyledConfirmationView>
  );
};

ConfirmationView.defaulProps = {
  appointment: {
    businessName: false,
  }
}

export default ConfirmationView;